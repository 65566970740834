body {
	font-family: sans-serif;
	text-align: center;
	/* display: flex; */
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
}

.app-container {
	height: 350px;
	width: 270px;
	background: #2b5870;
	border-radius: 20px;
	box-shadow: 10px 10px 38px 0px rgba(0, 0, 0, 0.75);
}

.temperature-display-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.temperature-display {
	display: flex;
	/* border-radius: 50%; */
	/* padding: 20px; */
	border-radius: 10px;
	color: #ffffff;
	height: 45px;
	width: 250px;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	border: 3px #ffffff solid;
	transition: background 0.5s;
}

.checkbutton {
	border-radius: 100px;
	height: 30px;
	width: 80px;
	font-size: 18px;
	color: #ffffff;
	background: rgb(51, 210, 165);
	border: 2px #ffffff solid;
}

.controlbutton {
	border-radius: 100px;
	height: 30px;
	width: 80px;
	font-size: 18px;
	color: #ffffff;
	background: rgb(41, 185, 229);
	border: 2px #ffffff solid;
}

.ebutton {
	border-radius: 100px;
	pad: 10px;
	height: 40px;
	width: 200px;
	font-size: 18px;
	color: #ffffff;
	background: rgb(51, 210, 165);
	/* border: 2px #ffffff solid; */
	text-align: auto;
}

/* button {
	border-radius: 100px;
	height: 65px;
	width: 65px;
	font-size: 24px;
	color: #ffffff;
	background: rgb(61, 138, 216);
	border: 2px #ffffff solid;
} */

button:hover {
	background: rgb(32, 125, 74);
	cursor: pointer;
}

button:focus {
	outline: 0;
}

.button-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.neutral {
	background: rgb(255, 255, 255);
	color: black;
}

.cold {
	background: #035aa6;
}

.hot {
	background: #ff5200;
}


 
