.wifi-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 40px;
    color: #007bff; /* Blue color for default state */
}

.wifi-container p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.wifi-icon {
    transition: transform 0.3s ease, color 0.3s ease;
}

.wifi-container:hover .wifi-icon {
    transform: scale(1.1);
    color: #0056b3; /* Darker blue on hover */
}

.wifi-container .wifi-off {
    color: #c2d8f4; /* Light steel blue for no signal */
}

.wifi-container .wifi-weak {
    color: #91c5f8; /* Dodger blue for weak signal */
}

.wifi-container .wifi-moderate {
    color: #6dbaf9; /* Steel blue for moderate signal */
}

.wifi-container .wifi-strong {
    color: #489cf6; /* Strong blue */
}

.wifi-container .wifi-excellent {
    color: #3899fb; /* Dark blue for excellent signal */
}
