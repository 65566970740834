@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

* {
	font-family: 'Sora', sans-serif;
	box-sizing: border-box;
	/* margin: 0; */
	/* padding: 0; */
	/* padding: 5rem; */
}

.myDIV {
	height: 300px;
	background-color: #FFFFFF;
}

.mymain {
	height: 100px;
	display: flex;
	flex-direction: row-reverse;
}

.mymain div {
	padding: 5px;
}

.flex-container {
	/* padding: 5rem;
    margin-top: 60px; */
	display: flex;
	position: sticky;
	/* background-color: #f1f1f1; */
}

.flex-container>div {
	background-color: DodgerBlue;
	color: white;
	width: 280px;
	height: 280px;
	/* margin-top: 60px; */
	text-align: center;
	margin: 10px;
	/* line-height: 75px; */
	font-size: 30px;
	flex-direction: row;
	position: sticky;
}

.parent {
	/* border: 1px solid black; */
	/* margin: 1rem; */
	padding: 5rem 1rem;
	/* text-align: center; */
}

.mainPage {
	/* border: 1px solid black; */
	/* margin: 1rem; */
	padding: 5rem 1rem;
	/* text-align: center; */
}

.child1 {
	display: inline-block;
	/* border: 1px solid red; */
	padding: 3rem 1rem;
	vertical-align: middle;
}

.child {
	display: inline-block;
	/* border: 1px solid red; */
	padding: 1rem 1rem;
	vertical-align: middle;
}

.chartBox {
	margin-top: 60;
	display: flex;
	flex-direction: row;
}

.myreact-icons {
	color: red;
	height: 2em;
}

.Chart-bigcontainer {
	height: 320px;
	width: 480px;
	background: #ffffff;
	margin: 10px;
	border-radius: 20px;
	box-shadow: 10px 10px 38px 0px rgba(55, 52, 52, 0.75);
}

.cardSensor-bigcontainer {
	height: 280px;
	width: 280px;
	background: #ffffff;
	border-radius: 20px;
	box-shadow: 10px 10px 38px 0px rgba(55, 52, 52, 0.75);
}

.cardSensor-bigcontainer1 {
	height: 420px;
	width: 580px;
	background: #ffffff;
	border-radius: 20px;
	box-shadow: 10px 10px 38px 0px rgba(55, 52, 52, 0.75);
}

.cardSensor-container {
	height: 100px;
	width: 100px;
	background: #ffffff;
	border-radius: 20px;
	box-shadow: 10px 10px 38px 0px rgba(55, 52, 52, 0.75);
}

.cardButton-container {
	height: 180px;
	width: 220px;
	background: #ffffff;
	border-radius: 20px;
	box-shadow: 10px 10px 38px 0px rgba(55, 52, 52, 0.75);
}

/* .button-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
} */

/* styles.css */

.react-switch {
	vertical-align: middle;
	margin-left: 4px;
}

.div_top_hypers {
	background-color: #eeeeee;
	display: inline;
}

.ul_top_hypers li {
	display: inline;
}

.B1 {
	padding: 0.4rem;
}

.child44 {
	/* display: inline-block; */
	/* border: 1px solid red; */
	padding: 2rem 3rem;
	vertical-align: middle;
}