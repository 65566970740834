body {
	font-family: sans-serif;
	text-align: center;
	/* display: flex; */
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
}

.app-container {
	height: 350px;
	width: 270px;
	background: #2b5870;
	border-radius: 20px;
	box-shadow: 10px 10px 38px 0px rgba(0, 0, 0, 0.75);
}

.temperature-display-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.temperature-display {
	display: flex;
	/* border-radius: 50%; */
	border-radius: 10px;
	color: #ffffff;
	height: 45px;
	width: 150px;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	border: 3px #ffffff solid;
	transition: background 0.5s;
}

 

 
 

.neutral {
	background: rgb(255, 255, 255);
	color: black;
}

.cold {
	background: #035aa6;
}

.hot {
	background: #ff5200;
}

.homeautomation-container {
	height: 135px;
	width: 285px;
	background: #ffffff;
	border-radius: 15px;
	box-shadow: 10px 10px 38px 0px rgba(133, 203, 225, 0.75);
	padding: .1rem 1rem;
}


.homeautomation-energycontainer {
	height: 135px;
	width: 285px;
	background: #ffffff;
	border-radius: 15px;
	box-shadow: 10px 10px 38px 0px rgba(133, 203, 225, 0.75);
}


.homeautomation-buttoncontainer {
	height: 135px;
	width: 135px;
	background: #ffffff;
	border-radius: 15px;
	box-shadow: 10px 10px 38px 0px rgba(133, 203, 225, 0.75);
	padding: .8rem 1rem;
}

.homeautomation-fanregulatorcontainer {
	height: 100px;
	width: 285px;
	background: #ffffff;
	border-radius: 15px;
	box-shadow: 10px 10px 38px 0px rgba(133, 203, 225, 0.75);
	padding: .8rem 1rem;
}

.child1 {
	display: inline-block;
	/* border: 1px solid red; */
	padding: .5rem .5rem;
	vertical-align: middle;
  }

  .childh {
	display: inline-block;
	/* border: 1px solid red; */
	padding: .5rem .5rem;
	vertical-align: middle;
  }



  