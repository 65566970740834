/* Userdetails1.css */

.container {
    padding: 50px;
    max-width: 1000px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-table th,
  .user-table td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .user-table th {
    background-color: #4caf50;
    color: white;
    font-weight: bold;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .status.enabled {
    color: green;
    font-weight: bold;
  }
  
  .status.disabled {
    color: red;
    font-weight: bold;
  }
  
  .toggle-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .toggle-button.enable {
    background-color: #4caf50;
  }
  
  .toggle-button.disable {
    background-color: #f44336;
  }
  
  .toggle-button:hover {
    opacity: 0.9;
  }
  