
/* Loading and error messages */
.loading,
.error,
.no-devices {
  font-size: 1.5em; /* Larger font for visibility */
  color: #555; /* Grey text for loading and error messages */
  margin: 20px 0; /* Space around messages */
}


